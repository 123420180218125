<template>
    <div class="numbers-container" v-observe-visibility="visibilityChanged">
        <div class="row justify-content-center nb-icon">
            <span :class="icon"></span>
        </div>
        <div class="row justify-content-center nb-number">
            <number
                animationPaused
                ref="number"
                :to="number"
                :duration="3"
                :delay="1"
                easing="Power1.easeOut"
            />
        </div>
        <div class="row justify-content-center nb-title">
            {{ title }}
        </div>
        <div class="row justify-content-center nb-desc">
            {{ desc }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Numbers",

    props: ["icon", "number", "title", "desc"],

    methods: {
        visibilityChanged(isVisible, entry) {
            if (isVisible) this.$refs.number.play()
        }
    }
}
</script>

<style lang="scss" scoped>
    .numbers-container {
        .row > * {
            width: auto !important;
        }
        .nb-icon {
            color: $primary;

            .icon {
                position: relative;
                font-size: 48px;
            }
        }

        .nb-number {
            font-size: 36px;
            font-family: $font-family-heading;
            font-weight: bold;
        }

        .nb-title {
            font-size: 18px;
        }

        .nb-desc {
            opacity: .6;
        }
    }
</style>