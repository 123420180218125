<template>
	<div class="about" :class="$mq">
		<HeroBanner :bgImage="require('../assets/img/logos/olinawhite.png')" />

		<div class="description container">
			<div class="top-stepper text-center">
				<h1 class="title mt-5 mb-0">NOUS FAISONS L'INCROYABLE.</h1>
				<h1 class="title mt-0 mb-3">NOUS NE PROMETTONS PAS L'IMPOSSIBLE.</h1>
				<p class="content h5 my-5">
					Chez Olina Lab, nous pensons que le succès d'une entreprise sur Internet réside dans des créations web innovantes. <br>
					Pour chaque projet, notre équipe crée un ensemble de solutions qui aident nos clients à développer leur entreprise.
				</p>
			</div>
		</div>

		<div class="our-numbers container">
			<h4>Quelques chiffres</h4>
		</div>
		<hr class="divider divider-default divider-full divider-xl mx-auto">

		<div class="row row-50 justify-content-sm-center offset-custom-2" style="max-width: 100%;">
            <div class="col-sm-10 col-md-6 col-lg-4 col-xl-3 mb-5">
				<Numbers
					icon="icon mdi mdi-responsive"
					:number="projects"
					title="projets"
					desc="concrétisés"
				/>
			</div>
			<div class="col-sm-10 col-md-6 col-lg-4 col-xl-3 mb-5">
				<Numbers
					icon="icon mdi mdi-handshake"
					:number="clients"
					title="clients"
					desc="accompagnés"
				/>
			</div>
			<div class="col-sm-10 col-md-6 col-lg-4 col-xl-3 mb-5">
				<Numbers
					icon="icon mdi mdi-calendar-star"
					:number="years"
					title="années d'expériences"
					desc=""
				/>
			</div>
		</div>

		<div class="why-us container">
			<div class="top-stepper text-center">
				<h3 class="title mt-5 mb-0">Pourquoi Olina Lab ?</h3>
				<p class="content h5 my-5">
					Nous créons des sites web uniques et à votre image. <br>
					Nous savons intégrer et analyser vos données, à l'aide d'API notamment. <br>
					Nous savons comment optimiser un site web pour une couverture efficace sur Google. <br>
					Nous fournissons toujours des projets fonctionnels sur toutes les plateformes. <br>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import HeroBanner from '@/components/Banner/HeroBanner.vue'
	import Numbers from '@/components/Numbers.vue'

	import { ourProjects } from '@/data/projects.js'

	export default {
		name: 'About',

		computed: {
			projects() {
				return ourProjects.projects.length + 10
			},

			clients() {
				return new Set(ourProjects.projects).size + 5
			},

			years() {
				return new Date().getFullYear() - 2019
			}
		},

		components: {
			HeroBanner,
			Numbers
		}
	}
</script>

<style lang="scss" scoped>
</style>